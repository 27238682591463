<template>
    <div>
        <v-container fluid class="grid-list-xl pt-0 mt-12">
            <v-row class="align-center justify-center">
                <v-col cols="12" md="9" class="px-0 mt-3">
                    <v-card flat tile class="pt-5">
                        <span class="ml-2">
                            <v-icon color="grey" class="pointer" onclick="javascript:history.back(-1);">mdi-arrow-left</v-icon>
                        </span>
                        <h3 class="text-center mb-5">{{ $t('ga.closeTitle') }}</h3>
                        <v-row>
                            <v-col md="3"></v-col>
                            <v-col md="6" sm="12">
                                <v-alert dense tile dark color="grey white--text" icon="mdi-information-outline" class="fs-14">
                                    {{ $t('ga.gaAlert') }}
                                </v-alert>
                                <!-- 第一步 -->
                                <v-col cols="12" class="fs-18 fw-bold">
                                    <v-icon>mdi-numeric-1-box-outline</v-icon>
                                    <span>{{ $t('ga.downloadGA') }}</span>
                                </v-col>
                                <v-col cols="12">
                                    <span>{{ $t('ga.downloadGAMsg') }}</span>
                                </v-col>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="6">
                                            <v-btn block tile color="light-blue" class="text-none white--text" href="https://itunes.apple.com/cn/app/google-authenticator/id388497605" target="_blank">
                                                <v-icon left>mdi-apple</v-icon>
                                                <span>App Store</span>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-btn block tile color="light-blue" class="text-none white--text" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">
                                                <v-icon left>mdi-google-play</v-icon>
                                                <span>Google Play</span>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-divider></v-divider>
                                </v-col>
                                <!-- 第二步 -->
                                <v-col cols="12" class="fs-18 fw-bold">
                                    <v-icon>mdi-numeric-2-box-outline</v-icon>
                                    <span>{{ $t('ga.iobotsCodeMsg') }}</span>
                                </v-col>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="12">
                                            <div>
                                                <svg id="图层_1" data-name="图层 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1236 456"><defs></defs><rect class="cls-1" width="1236" height="456"/><text class="cls-2" transform="translate(53.78 91.5)">ioBots</text><text class="cls-3" transform="translate(47.26 293.54)">123456</text><text class="cls-4" transform="translate(53.78 391.22)">xxx.xxx@iobots.pro</text><path class="cls-5" d="M1164,385V360a26,26,0,1,0,26,26,26.3,26.3,0,0,0-.18-3.09Z"/></svg>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-divider></v-divider>
                                </v-col>
                                <!-- 第三步: Otp -->
                                <div v-if="isOtp">
                                    <v-col cols="12" class="fs-18 fw-bold">
                                        <v-icon>mdi-numeric-3-box-outline</v-icon>
                                        <span>{{ $t('ga.verificationCodeMsg') }}</span>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-text-field v-model="otp" single-line outlined counter="6" @keyup.enter="closeByOtp"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <span>{{ $t('ga.lostVerificationCode') }}</span>
                                        <span class="blue--text pointer ml-2" @click="isOtp = false">{{ $t('ga.useSecretKey') }}</span>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-btn block tile color="light-blue" class="text-none white--text" @click="closeByOtp">
                                            <span>{{ $t('common.submit') }}</span>
                                        </v-btn>
                                    </v-col>
                                </div>
                                <div v-if="!isOtp">
                                    <!-- 第三步: Secret -->
                                    <v-col cols="12" class="fs-18 fw-bold">
                                        <v-icon>mdi-numeric-3-box-outline</v-icon>
                                        <span>{{ $t('ga.enterSecretKey') }}</span>
                                    </v-col>
                                    <v-col cols="12" md="5">
                                        <v-text-field v-model="authSecret" single-line outlined counter="16" @keyup.enter="closeBySecret"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <span class="blue--text pointer" @click="isOtp = true">{{ $t('ga.useVerificationCode') }}</span>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-btn block tile color="light-blue" class="text-none white--text" @click="closeBySecret">
                                            <span>{{ $t('common.submit') }}</span>
                                        </v-btn>
                                    </v-col>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<style>
.cls-1{fill:#efefef;}.cls-2{font-size:43px;font-family:Arial-BoldMT, Arial;font-weight:700;letter-spacing:0.01em;}.cls-2,.cls-4{fill:#231815;}.cls-3{font-size:193px;fill:#446eb6;}.cls-3,.cls-4{font-family:ArialMT, Arial;}.cls-4{font-size:47px;}.cls-5{fill:#4f4e4e;}
</style>
<script>
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    import QRCode from 'qrcode';
    import Bus from '@/components/bus/Bus';
    export default {
        data(){
            return {
                // 用户秘钥信息
                userInfo: {},
                // 验证码
                otp: null,
                // 秘钥
                authSecret: null,
                // 是否使用验证码关闭
                isOtp: true
            }
        },
        created(){

        },
        mounted(){
            if (!this.user.token) {
                this.$router.push({ name: "signIn" });
            }
        },
        components:{ },
        computed: {
            ...mapGetters(['user'])
        },
        watch:{

        },
        methods: {
            // 提交请求
            closeByOtp(){
                let api = config.baseUrl + '/ga/private/close/otp';
                let param = {otp: this.otp};
                this.$http.post(api, param).then(response => {
                    let data = response.data;
                    this.otp = null;
                    if (data.code == 200) {
                        // 开启成功
                        Bus.$emit('emitGetMe', {});
                        this.$router.push({name: 'account'});
                    } else {
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                    }
                }, error => {
                    this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
                });
            },
            // 提交请求
            closeBySecret(){
                let api = config.baseUrl + '/ga/private/close/secret';
                let param = {authSecret: this.authSecret};
                this.$http.post(api, param).then(response => {
                    let data = response.data;
                    this.authSecret = null;
                    if (data.code == 200) {
                        // 关闭成功
                        Bus.$emit('emitGetMe', {});
                        this.$router.push({name: 'account'});
                    } else {
                        this.authSecret = null;
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                    }
                }, error => {
                    this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
                });
            },
        }
    }
</script>
